import React from "react";
import { Flex, Box, Image, Button } from "theme-ui";
import { entryAnimation } from "@utils";
import { useMounted, useMountAfter } from "@hooks";
import { LinkWrapper } from "@snippets";
import store, { useRecoilState, useSetRecoilState } from "@store";
import { useRouter } from "@backpackjs/storefront";
import { CollectionProductLinks } from "./CollectionProductLinks";
import { MenuItem } from "./MenuItem";

export const MobileMenu = ({ collectionLinks, exploreLinks }) => {
  const [activeMobileMenuDrawer, setActiveMobileMenuDrawer] = useRecoilState(
    store.activeMobileMenuDrawer
  );
  const [activeMobileShopNav, setActiveMobileShopNav] = useRecoilState(
    store.activeMobileShopNav
  );
  const collections =
    collectionLinks?.map((collection) => {
      return {
        id: collection.id,
        title: collection.link.text,
        subItems: collection.subItems,
        link: collection.link,
        style: collection.style,
      };
    }) || [];
  const [mounted] = useMounted();
  const [mountItems] = useMountAfter({ delay: 200 });
  const router = useRouter();
  const setMenuDrawer = useSetRecoilState(store.menuDrawer);
  const setOverlay = useSetRecoilState(store.overlay);
  const goToLinkAndToggle = React.useCallback((subItem) => {
    if (subItem?.link?.url && subItem?.link?.url !== "#") {
      // navigate
      router.push(subItem.link.url);
    }
    // close the drawer
    setMenuDrawer(false);
    setOverlay(false);
  });
  const menuSetClick = (id) => {
    if (id === activeMobileMenuDrawer) {
      setActiveMobileMenuDrawer(null);
    } else {
      setActiveMobileMenuDrawer(id);
    }
  };
  const menuShopToggle = () => {
    setActiveMobileShopNav(!activeMobileShopNav);
  };

  return (
    <Flex
      sx={{
        margin: "auto",
        alignItems: "flex-start",
        flexDirection: "column",
        display: [null, null, null, null, "none"],
        minHeight:
          "calc(105vh - var(--header-height) - var(--promoBar-height))",
        pb: "2rem",
      }}
    >
      {collections?.length ? (
        <Flex
          variant="flex.column"
          data-comp="MobileCollectionLinks"
          sx={{
            bg: "#333",
            width: "100%",
          }}
        >
          <Button
            sx={{
              display: "flex",
              gap: 5,
              alignItems: "center",
              justifyContent: "center",
              px: 6,
            }}
            onClick={() => {
              menuShopToggle();
            }}
          >
            <Box
              variant="text.24.r"
              sx={{
                textAlign: "center",
                color: "white",
                py: "15px",
              }}
            >
              Shop
            </Box>
            <Image
              src="/svg/chevron-down.svg"
              alt="chevron-down"
              sx={{
                width: "12px",
                transform: activeMobileShopNav
                  ? "rotate(180deg)"
                  : "rotate(0deg)",
                transition: "transform 0.3s ease-in-out",
                userSelect: "none",
                filter: "brightness(0) invert(1)",
              }}
              draggable={false}
            />
          </Button>
          <Box>
            {collections?.map((collection, index) => (
              <Flex
                key={index}
                variant="flex.column"
                sx={{
                  overflow: "hidden",
                  height: 0,
                  transition: "all 0.33s",
                  ...(activeMobileShopNav
                    ? {
                        height: "auto",
                      }
                    : {}),
                }}
              >
                {collection?.link?.url && collection?.style ? (
                  <LinkWrapper
                    variant="buttons.link"
                    href={collection?.link?.url}
                    onClick={(e) => {
                      e.preventDefault();
                      goToLinkAndToggle(collection);
                    }}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "gold",
                      px: 10,
                      my: "20px",
                      mx: 6,
                      border: "1px solid",
                      borderColor: "gold",
                      height: "44px",
                      fontSize: 2,
                      fontWeight: 600,
                      lineHeight: "1",
                      "&:hover": {
                        "&:before": {
                          opacity: 0,
                        },
                      },
                    }}
                  >
                    {collection.link.text}
                  </LinkWrapper>
                ) : (
                  <>
                    <Button
                      className="menu-set-header"
                      sx={{
                        display: "flex",
                        gap: 5,
                        alignItems: "center",
                        justifyContent: "space-between",
                        px: 6,
                        py: "19px",
                        borderTop: "1px solid #4e4f50",
                      }}
                      onClick={() => {
                        menuSetClick(collection.id);
                      }}
                    >
                      <Box
                        sx={{
                          color: "white",
                          fontSize: 5,
                          textTransform: "uppercase",
                          lineHeight: "1.375",
                        }}
                      >
                        {collection.title}
                      </Box>
                      <Image
                        src="/svg/chevron-down.svg"
                        alt="chevron-down"
                        sx={{
                          width: "12px",
                          transform:
                            collection.id === activeMobileMenuDrawer
                              ? "rotate(180deg)"
                              : "rotate(0deg)",
                          transition: "transform 0.3s ease-in-out",
                          userSelect: "none",
                          filter: "brightness(0) invert(1)",
                        }}
                        draggable={false}
                      />
                    </Button>
                    {collection.id === activeMobileMenuDrawer && (
                      <Box
                        sx={{
                          px: 6,
                          borderBottom: "1px solid #4e4f50",
                          mb: "-1px",
                          overflow: "hidden",
                          height: "auto",
                          transition: "all 0.33s",
                          width: "100%",
                        }}
                      >
                        <CollectionProductLinks
                          title={collection.title}
                          links={collection?.subItems || []}
                          isMobile={activeMobileMenuDrawer}
                        />
                      </Box>
                    )}
                  </>
                )}
              </Flex>
            ))}
          </Box>
        </Flex>
      ) : null}

      <Flex
        variant="flex.column"
        data-comp="MobileExploreLinks"
        sx={{
          mt: 0,
          mb: 8,
          width: "100%",
          ...entryAnimation({
            entered: mounted,
            fadeIn: true,
            index: 1,
            delay: 0,
          }),
        }}
      >
        <Button
          className="menu-set-header"
          sx={{
            display: "flex",
            gap: 5,
            alignItems: "center",
            justifyContent: "space-between",
            px: 6,
            py: "19px",
            borderTop: "1px solid #4e4f50",
          }}
          onClick={() => {
            menuSetClick("explorenavset");
          }}
        >
          <Box
            sx={{
              color: "white",
              fontSize: 5,
              textTransform: "uppercase",
              lineHeight: "1.375",
            }}
          >
            Explore
          </Box>
          <Image
            src="/svg/chevron-down.svg"
            alt="chevron-down"
            sx={{
              width: "12px",
              transform:
                activeMobileMenuDrawer === "explorenavset"
                  ? "rotate(180deg)"
                  : "rotate(0deg)",
              transition: "transform 0.3s ease-in-out",
              userSelect: "none",
              filter: "brightness(0) invert(1)",
            }}
            draggable={false}
          />
        </Button>
        <Box
          sx={{
            borderBottom: "1px solid #4e4f50",
            overflow: "hidden",
            height: 0,
            transition: "all 0.33s",
            ...(activeMobileMenuDrawer === "explorenavset"
              ? {
                  height: "auto",
                }
              : {}),
          }}
        >
          <Flex
            variant="flex.row"
            sx={{
              flex: 1,
              flexWrap: "wrap",
              gap: "20px",
              px: 6,
              pb: "2rem",
            }}
          >
            {activeMobileMenuDrawer &&
              exploreLinks?.map((item, exploreIndex) => {
                return (
                  <MenuItem
                    item={item}
                    index={exploreIndex}
                    key={item.id}
                    cardSize="large"
                    hasOptionsGrid={false}
                    sx={{
                      opacity: 0,
                      ...entryAnimation({
                        entered: mountItems,
                        fadeIn: true,
                        slideIn: false,
                        duration: 50,
                        index: exploreIndex,
                        delay: 50,
                      }),
                    }}
                  />
                );
              })}
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
};
