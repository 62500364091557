/* eslint-disable no-nested-ternary */
import React, { useMemo, useEffect, useState } from 'react';
import { Box, Paragraph, Button } from 'theme-ui';
import { useRouter } from 'next/router';

import { entryAnimation, quantityFromOption } from '@utils';
import { useMountAfter } from '@hooks';
import store, { useRecoilValue, useSetRecoilState } from '@store';
import { LinkWrapper } from '@snippets';

export function OptionsGridItem({
  index,
  isGrouping,
  isOptionsMap,
  isProduct,
  option,
  optionName,
  onClick,
  onMouseOver,
  product,
  productVariants,
  selectedIndex,
  selectedOption,
  selectedVariant,
  startAtPrice,
  theme,
  ...props
}) {
  const router = useRouter();
  const activeQuantity = useRecoilValue(
    store.activeQuantity(selectedVariant?.product?.handle)
  );
  const [mounted] = useMountAfter({ delay: index * 100 });
  const [discount, setDiscount] = useState(null);
  const [productName, setProductName] = useState('');

  const isSelectionOptions = ['Selection', 'Size', 'Pack', 'Title'].includes(
    optionName
  );

  const isSelected = isSelectionOptions
    ? activeQuantity === index + 1
    : // old logic
    isOptionsMap
    ? isGrouping
      ? product?.handle === option
      : router.isReady && selectedOption === option
    : index === selectedIndex;

  const setDiscountOnVariantChange = () => {
    const isSizeSelectionOption =
      typeof option === 'string' && ['Pack', 'Bottle', 'Box'].includes(option);
    if (!isSizeSelectionOption) return;
    if (isOptionsMap && isProduct) return;

    const quantity = quantityFromOption(option);

    if (!quantity) return;

    let _discount;
    switch (quantity) {
      case 1:
        // no discount on 1 of anything
        break;
      case 2:
        // no discount on 1 of anything
        _discount = 5;
        break;

      default:
        _discount = 10;
        break;
    }

    _discount && setDiscount(_discount);
  };

  const setProductNameOnVariantChange = () => {
    if (isOptionsMap && !isGrouping) return;
    const optionProduct = product?.variants?.find((variant) => {
      return variant.product?.handle === option;
    });
    const optionNameTag = optionProduct?.product?.tags?.find((tag) => {
      return tag.startsWith('optionName::');
    });
    const optionName = optionNameTag?.split('::')[1].trim();
    setProductName(optionName || optionProduct?.product?.title || option);
  };

  useEffect(setDiscountOnVariantChange, [
    isProduct,
    option,
    selectedVariant,
    productVariants,
    isOptionsMap,
  ]);
  useEffect(setProductNameOnVariantChange, [
    isGrouping,
    option,
    product,
    isOptionsMap,
  ]);

  const themeSx = useMemo(() => {
    const isDark = theme === 'dark';
    return {
      borderColor: isDark
        ? isSelected
          ? 'gold'
          : 'gray.8'
        : isSelected
        ? 'gold'
        : 'gray.2',
      bg: isDark
        ? isSelected
          ? 'black'
          : 'gray.8'
        : isSelected
        ? 'white'
        : 'gray.2',
      selectedBg: isDark ? 'black' : 'white',
      color: isDark ? (isSelected ? 'gold' : 'white') : 'black',
      subtextColor: isDark ? 'gray.3' : 'gray.7',
    };
  }, [theme, isSelected]);

  const setMenuDrawer = useSetRecoilState(store.menuDrawer);
  const setOverlay = useSetRecoilState(store.overlay);
  const goToLinkAndToggle = React.useCallback((subItem) => {
    if (subItem?.link?.url && subItem?.link?.url !== '#') {
      // navigate
      router.push(subItem.link.url);
    }
    // close the drawer
    setMenuDrawer(false);
    setOverlay(false);
  });

  return (
    <Box
      as="li"
      data-comp="OptionsGridItem"
      key={option.title}
      sx={{
        ...entryAnimation({
          entered: mounted,
          fadeIn: true,
          slideIn: false,
          duration: 500,
          index: 1,
          delay: 0,
        }),
      }}
    >
      {option?.link?.url && option?.link?.url !== '#' ? (
        option?.style ? (
          <LinkWrapper
            variant="buttons.link"
            href={option?.link?.url}
            onClick={(e) => {
              e.preventDefault();
              goToLinkAndToggle(option);
            }}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: theme === 'dark' ? 'gold' : 'black',
              px: 10,
              mt: 3,
              border: '1px solid',
              borderColor: theme === 'dark' ? 'gold' : 'black',
              height: '44px',
              fontSize: 2,
              fontWeight: 600,
              lineHeight: '1',
              '&:hover': {
                backgroundColor: theme === 'dark' ? 'gold' : 'black',
                color: theme === 'dark' ? 'black' : 'gold',
                '&:before': {
                  opacity: 0,
                },
              },
            }}
          >
            {option.link.text}
          </LinkWrapper>
        ) : (
          <LinkWrapper
            variant="buttons.link"
            href={option?.link?.url}
            onClick={(e) => {
              e.preventDefault();
              goToLinkAndToggle(option);
            }}
            onMouseOver={() => onMouseOver({ option, selectedIndex: index })}
            sx={{
              width: 'max-content',
              '&:before': {
                transition: isSelected ? 'all 0.33s' : {},
                opacity: isSelected ? 1 : {},
                animation: isSelected
                  ? 'in 0.33s cubic-bezier(.52,.02,.31,.99) normal'
                  : {},
              },
            }}
          >
            <Paragraph
              variant="label.3"
              sx={{
                fontSize: 5,
                lineHeight: '1.38',
                fontWeight: 400,
              }}
            >
              {option.link.text}
            </Paragraph>
          </LinkWrapper>
        )
      ) : (
        <Button
          variant="buttons.link"
          sx={{
            '&:before': {
              transition: isSelected ? 'all 0.33s' : {},
              opacity: isSelected ? 1 : {},
              animation: isSelected
                ? 'in 0.33s cubic-bezier(.52,.02,.31,.99) normal'
                : {},
            },
          }}
          onMouseOver={() => onMouseOver({ option, selectedIndex: index })}
          onClick={() => onClick({ option, selectedIndex: index })}
          {...props}
        >
          <Paragraph
            variant="label.3"
            sx={{
              fontSize: 5,
              lineHeight: '1.38',
              fontWeight: 400,
            }}
          >
            {isOptionsMap ? (isGrouping ? productName : option) : option?.title}
          </Paragraph>

          {!!discount && discount > 0 && (
            <Paragraph
              sx={{
                fontSize: '10px',
                lineHeight: '14px',
                color: themeSx.subtextColor,
              }}
            >
              {discount}% off!
            </Paragraph>
          )}
        </Button>
      )}
    </Box>
  );
}
