export function createCustomObject(properties) {
  const customObject = {};

  Object.keys(properties).forEach((key) => {
    if (
      Object.prototype.hasOwnProperty.call(properties, key) &&
      properties[key]
    ) {
      customObject[key] = properties[key];
    }
  });
  return customObject;
}

export const addToKlaviyoList = async ({ email, phoneNumber, listId }) => {
  const { search } = window.location;
  const params = new URLSearchParams(search);

  const source = params.get("source");

  try {
    const response = await fetch("/api/klaviyo", {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify({
        action: "subscribe",
        email,
        phone: phoneNumber,
        source,
        acceptsMarketing: !!phoneNumber,
        listId,
      }),
    });
    if (response?.ok) return true;
    if (!response.ok) {
      const data = await response.json();
      return data;
    }

    return response;
  } catch (error) {
    console.error({ error });
    return error;
  }
};

export const trackKlaviyoEvent = async ({
  phoneNumber: phone_number,
  email,
  listName = null,
  eventType = "list",
}) => {
  const subscriptionType = {
    list: "Subscribed to list",
  };

  if (!eventType) return { error: "error" };

  const endpoint = `https://a.klaviyo.com/client/events/?company_id=${process.env.NEXT_PUBLIC_KLAVIYO_ACCOUNT_ID}`;
  const options = {
    method: "POST",
    headers: {
      accept: "application/json",
      revision: "2023-07-15",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      data: {
        type: "event",
        attributes: {
          metric: {
            data: {
              type: "metric",
              attributes: { name: subscriptionType[eventType] },
            },
          },
          profile: {
            data: {
              type: "profile",
              attributes: {
                ...createCustomObject({ email, phone_number }),
              },
            },
          },
          properties: {
            List: listName,
          },
        },
      },
    }),
  };

  try {
    const response = await fetch(endpoint, options);

    if (response?.ok) return true;
    return { errors: "error" };
  } catch (error) {
    return { errors: [error] };
  }
};
