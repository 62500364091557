/* eslint-disable no-nested-ternary */
import React from 'react';
import { Box, Flex, Paragraph } from 'theme-ui';
import { useRouter } from '@backpackjs/storefront';

import store, { useSetRecoilState } from '@store';
import { BackpackImage, LinkWrapper } from '@snippets';

export const MenuItem = ({ item, isMobile, ...props }) => {
  const router = useRouter();
  const setMenuDrawer = useSetRecoilState(store.menuDrawer);
  const setOverlay = useSetRecoilState(store.overlay);
  const hasImage = item?.images?.length;
  const goToLinkAndToggle = React.useCallback((subItem) => {
    if (subItem?.link?.url && subItem?.link?.url !== '#') {
      // navigate
      router.push(subItem.link.url);
    }
    // close the drawer
    setMenuDrawer(false);
    setOverlay(false);
  });
  return (
    <Flex
      as="li"
      data-comp="MenuItem"
      variant="flex.column.start"
      {...props}
      sx={{
        ...(props.hasOptionsGrid
          ? {
              width: [
                'calc((100% / 2) - (20px / 2))',
                null,
                null,
                null,
                'calc((100% / 4) - (20px * 3 / 4))',
              ],
            }
          : {
              width: [
                'calc((100% / 2) - (20px / 2))',
                null,
                null,
                null,
                'calc((100% / 6) - (20px * 5 / 6))',
              ],
            }),
        ...props.sx,
      }}
    >
      {item ? (
        hasImage ? (
          <LinkWrapper
            ariaLabel={
              item?.images?.[0]?.alt
                ? `Go to ${item.images[0].alt} page.`
                : `Go to ${item.link.text} page.`
            }
            variant="flex.row.start.center"
            href={item.link.url}
            onClick={(e) => {
              e.preventDefault();
              goToLinkAndToggle(item);
            }}
            onKeyUp={(e) => {
              e.preventDefault();
              if (e && (e.key === ' ' || e.key === 'Spacebar')) {
                goToLinkAndToggle(item);
              }
            }}
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              background: isMobile
                ? item?.background || '#1a1a1a'
                : item?.background || '#333',
              border: '2px solid #4e4f50',
              borderRadius: '6px',
              overflow: 'hidden',
              '>span': {
                flexShrink: 0,
              },
              ' .menu-item-card-text-wrap div': {
                backgroundImage:
                  'linear-gradient(rgba(51, 51, 51, 1), rgba(51, 51, 51, 1)), linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 1))',
                backgroundSize: '100% 1px, 0 1px',
                backgroundPosition: '100% 100%, 0 100%',
                backgroundRepeat: 'no-repeat',
                transition: 'background-size 0.33s linear',
                display: 'inline',
              },
              '&:hover': {
                borderColor: 'gold',
                img: {
                  transform: 'scale(1.1)',
                  transition: 'all 0.33s',
                },
                ' .menu-item-card-text-wrap div': {
                  backgroundSize: '0 1px, 100% 1px',
                },
                ' .menu-item-card-text-wrap div:before': {
                  opacity: 0,
                },
              },
              ...(props.cardSize === 'small'
                ? {
                    img: {
                      // borderRadius: '6px 0 0 6px',
                    },
                    ' .menu-item-card-img-wrap > span': {
                      width: [
                        '70px !important',
                        null,
                        null,
                        null,
                        '80px !important',
                      ],
                      height: '100% !important',
                      minHeight: ['70px', null, null, null, '90px'],
                      display: 'block !important',
                    },
                  }
                : {
                    img: {
                      // borderRadius: [
                      //   '6px 0 0 6px',
                      //   null,
                      //   null,
                      //   null,
                      //   '6px 6px 0 0',
                      // ],
                    },
                    flexDirection: ['row', null, null, null, 'column'],
                    width: '100%',
                    height: [null, null, null, null, '100%'],
                    minHeight: [null, null, null, null, '200px'],
                    ' .menu-item-card-img-wrap > span': {
                      width: [
                        '70px !important',
                        null,
                        null,
                        null,
                        '100% !important',
                      ],
                      height: '100% !important',
                      position: [null, null, null, null, 'relative !important'],
                      flexShrink: [null, null, null, null, 0],
                      minHeight: ['70px', null, null, null, '0'],
                    },
                    ' .menu-item-card-text-wrap': {
                      display: [null, null, null, null, 'flex'],
                      alignItems: [null, null, null, null, 'center'],
                      height: [null, null, null, null, '100%'],
                    },
                  }),
            }}
          >
            <Box
              className="menu-item-card-img-wrap"
              sx={{
                flexShrink: '0',
                aspectRatio: ['1/1', null, null, null, '80/90'],
                height: ['70px', null, null, null, 'auto'],
                overflow: 'hidden',
                ...(props.cardSize === 'small'
                  ? {}
                  : {
                      display: [
                        'block !important',
                        null,
                        null,
                        null,
                        'none !important',
                      ],
                    }),
              }}
            >
              <BackpackImage
                alt={item.images[0].alt}
                src={item.images[0].image?.src || item.images[0].image}
                sx={{
                  width: 80,
                  height: 100,
                  objectFit: 'cover',
                }}
              />
            </Box>
            <Flex
              className="menu-item-card-img-wrap"
              sx={{
                ...(props.cardSize === 'small'
                  ? {
                      display: 'none !important',
                    }
                  : {
                      display: [
                        'none !important',
                        null,
                        null,
                        null,
                        'flex !important',
                      ],
                    }),
                width: '100%',
                aspectRatio: '210/150',
              }}
            >
              <BackpackImage
                alt={item.images[0].alt}
                src={item.images[0].image?.src || item.images[0].image}
                sx={{
                  width: 210,
                  height: 150,
                  objectFit: 'cover',
                }}
              />
            </Flex>
            <Box
              className="menu-item-card-text-wrap"
              sx={{
                px: [4, null, null, null, 4],
                m: 'auto',
                minHeight: [null, null, null, null, '50px'],
                height: [null, null, null, null, 'auto !important'],
                ...(props.cardSize === 'small'
                  ? {
                      textAlign: ['center', null, null, null, 'left'],
                    }
                  : {
                      textAlign: 'center',
                    }),
              }}
            >
              <Paragraph
                variant="buttons.link"
                sx={{
                  color: `${item?.textColor || '#fff'}`,
                  fontSize: [0, null, null, null, 4],
                }}
              >
                {item.link.text}
              </Paragraph>
            </Box>
          </LinkWrapper>
        ) : (
          // Link only i.e explore links
          <LinkWrapper
            variant="buttons.link"
            href={item?.link?.url}
            onClick={(e) => {
              e.preventDefault();
              goToLinkAndToggle(item);
            }}
            sx={{
              color: `${item?.textColor || '#fff'}`,
              pt: 1,
            }}
            ariaLabel={item.link.text}
          >
            {item.link.text}
          </LinkWrapper>
        )
      ) : null}
    </Flex>
  );
};
