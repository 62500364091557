const menuItemLink = [
  {
    label: 'Link',
    name: 'link',
    component: 'link',
  },
];

const imagesWithAlt = {
  label: 'Images',
  name: 'images',
  component: 'group-list',
  itemProps: {
    label: '{{item.alt}}',
  },
  fields: [
    {
      label: 'Alt',
      name: 'alt',
      component: 'text',
    },
    {
      label: 'Image',
      name: 'image',
      component: 'image',
      defaultValue: '',
    },
  ],
};

const colors = [
  {
    value: '#ffffff',
    label: 'Background',
  },
  {
    value: '#1a1a1a',
    label: 'Text',
  },
  {
    value: '#1a1a1a',
    label: 'Black',
  },
  {
    value: '#ffffff',
    label: 'White',
  },
  {
    value: '#cab683',
    label: 'Gold',
  },
  {
    value: '#88723A',
    label: 'Dark Gold',
  },
  {
    value: '#ac1b2e',
    label: 'Red',
  },
  {
    value: '#FBFBFB',
    label: 'Near White',
  },
  {
    value: '#F2F3F4',
    label: 'Light Gray',
  },
  {
    value: '#E6E7E9',
    label: 'Light Gray',
  },
  {
    value: '#D7D9DB',
    label: 'Light Gray',
  },
  {
    value: '#AFAFAF',
    label: 'Gray',
  },
  {
    value: '#868686',
    label: 'Gray',
  },
  {
    value: '#6F6F70',
    label: 'Dark Gray',
  },
  {
    value: '#4E4F50',
    label: 'Dark Gray',
  },
  {
    value: '#333333',
    label: 'Off Black',
  },
];

const menuItemsWithImages = [
  // level 1
  ...menuItemLink,
  {
    label: 'Style',
    name: 'style',
    component: 'toggle',
    description: 'Toggle between different styles',
    toggleLabels: {
      true: 'Button',
      false: 'Link',
    },
    defaultValue: false,
  },
  imagesWithAlt,
  {
    label: 'Sub Items',
    name: 'subItems',
    component: 'group-list',
    itemProps: {
      label: '{{item.link.text}}',
    },
    defaultItem: {
      name: 'New menu item',
      link: {
        text: '',
        url: '#',
      },
    },
    fields: [
      // level 2
      ...menuItemLink,
      imagesWithAlt,
      {
        name: 'background',
        label: 'Background Color',
        component: 'select',
        options: colors,
        defaultValue: 'facebook',
      },
      {
        name: 'textColor',
        label: 'Text Color',
        component: 'color',
      },
    ],
  },
];

export { imagesWithAlt, menuItemLink, menuItemsWithImages };
